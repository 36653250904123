import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row, Table, Form, Button
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { distrixService } from "../../service/api.service";
import moment from "moment"
import { ThreeDotSpinner } from "../loader";
import {apiUrl} from "../../config/config";
// apiUrl
function UnprocessedTransactionList() {
    const [loader, setLoader] = useState(false)
    const [transactions, settransactions] = useState([])
    const [transactionId, settransactionId] = useState()
    const [totalCount, setTotalCount] = useState()
    const [page, setPage] = useState(1)

    const [type, settype] = useState("")
    const [start_date, setstart_date] = useState("")
    const [end_date, setend_date] = useState("")
    useEffect(() => {
        gettransaction(page, type)
    }, [])
 
    async function gettransaction(page, type) {
        try {
            const params = {
                
                page: page,
                type: type
            }
            setLoader(true)
            const response = await distrixService.failed_transaction(params,type)
            if (response?.status == 200) {
                settransactions(response?.data.data)
                setTotalCount(response?.data?.total_count)
                setLoader(false)
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false)
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                setLoader(false)

                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    (value) => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }


    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        setPage(pageNumber);
        gettransaction(pageNumber, type)
    }
    function handleuserType(usertype) {
       
        settype(usertype);
        gettransaction(page, usertype)
    }
   async function reinitiate_payment(id){
    setLoader(true)
    try {
     

      // const response=await distrixService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await distrixService.re_initiate_payment(id)
      console.log("status====",response)
      if (response.status == 200) {
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {
            gettransaction(page, type)
           
          settransactionId()
        })

      }
     
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
    // window.open(apiUrl+"/download_csv/?type=transaction&start_date="+start_date+"&end_date="+end_date)
    }

    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">

                        <Row className="row mb-3 justify-content-center align-items-center">
                            <Col md={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-end m-0">Unprocessed Transaction Management </h2>
                                    </div>

                                    <div className="heading-top-area-right">
                                
                                         
                             
                                            {/* <Col md={2}>
                                                <Button type='button' variant='unset' onClick={()=>gettransaction(page)}>Apply</Button>
                                                <Button className="addtruck-btn" style={{color:"#fff"}} variant="unset" type="button"  onClick={()=>get_transactions(page)}>Apply</Button>

                                            </Col> */}

                                     
                                     
                                        <div className="user-type-top">
                                            <label>User Type</label>
                                            <div className="arrow-down-area">
                                            <select
                          className="form-select"
                          onChange={(e) => handleuserType(e.target.value)}
                        >
                          <option value="">Select Type</option>
                          <option value="Wholesaler" selected={type === "wholesaler" ? true : false}>Wholesaler</option>
                          <option value="Reseller" selected={type === "reseller" ? true : false}>Reseller</option>
                          <option value="Trucker" selected={type === "trucker" ? true : false}>Trucker</option>
                        </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>User Type</th>
                                                <th>Admin Commission</th>
                                                <th>Amount</th>
                                                <th>Transaction Type</th>
                                                <th>Order</th>
                                                <th>Date</th>
                                        
                                                <th>Pay Out Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions?.length > 0
                                                && transactions.map((data, index) => {
                                                    return (<tr >
                                                        <td>{data.id}</td>
                                                        <td>{data.user.groups[0].name}</td>
                                                        
                                                        <td>{data.admin_commission == 0 ? "-" : data.admin_commission}</td>
                                                        <td>{data.amount}</td>
                                                        <td>{data.transaction_type}</td>
                                                        <td>{data.order.id}</td>
                                                        <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                                                        <td>{data.status}</td>
                                                    <td>
                                        <div className="export-csv-btn">
                                                         <Button type="button" variant="unset" onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: `Reinitiate Payment?`,
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    })
                                      .then((value) => {
                                        console.log("=====>>>>",value)
                                        if (value) {
                                          // userStatus(data?.id)
                                          reinitiate_payment(data?.id)
                                        

                                        } else {
                                          console.log("NO button clicked or popup closed");
                                        }
                                      })

                                  } >Reintiate Payment</Button>
                                                                      </div></td>
                                                        
                                                    </tr>)
                                                })}
                                        </tbody>
                                    </Table>

                                    {transactions && transactions.length > 0 && totalCount > 12 ?

                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={12}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div></>
    );
};
export default UnprocessedTransactionList;