import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table
} from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { distrixService } from "../../service/api.service";
import moment from "moment";
function LoadmanagementPending() {
  const [loadsData, setLoadsData] = useState("")
  const [totalItems, setTotalItems] = useState("")
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  
  useEffect(() => {
    setPage(1)
    getLoadManagementList(1)
  }, [])

  async function getLoadManagementList(pageno) {
    setLoader(true)
    try {
      const params = {
        page: pageno
      }
      const response = await distrixService.LoadManagementPendingList(params)
      if (response?.status == 200) {
        setLoader(false)
        setLoadsData(response?.data?.data)
        setTotalItems(response?.data?.total_count)
        console.log("loadslisting....", response?.data?.data)

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        })
      } 
    }
  }

  function handlePageChange(pageNumber) {
    console.log("??????", pageNumber)
    setPage(pageNumber)
    getLoadManagementList(pageNumber)
  }

  
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
        

            <Row className="row justify-content-center align-items-center mb-3">
                                                 <Col md={11}>
                                                        <div className="heading-top-area">
                                                               <div className="d-flex ">
                                                                      <h2 className="align-self-center m-0">   { 'Pending Loads' }</h2>
                                                               </div>
                                                               {/* <div className="heading-top-area-right">
                                                                      <div className="export-csv-btn">
                                                                             <Button type="button" variant="unset">Export CSV</Button>
                                                                      </div>
                                                               </div> */}
                                                        </div>
                                                 </Col>
                                          </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Order Id</th>
                        <th>Wholesaler Name</th>
                        <th>Delivery Charge</th>
                        <th>Packed By</th>
                        <th>Total CBM</th>
                        <th>Total Weight</th>
                        <th>Dispatching</th>
                       

                      </tr>
                    </thead>
                    <tbody>
                      {loadsData && loadsData.length > 0 ? (loadsData.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{(page - 1) * 10 + (index + 1)}</td>
                            <td>{items?.id ? <>{items?.id}</> : <>-</>}</td>
                            <td>{items?.wholesaler_business_profile?.business_name ? <>{items?.wholesaler_business_profile?.business_name}</> : <>-</>}</td>
                            <td>{items?.delivery_charge ? <>₱ {Number(items?.delivery_charge).toFixed(2)}</> : <>-</>}</td>
                            <td>{items?.estimated_packing ? <>{moment(items?.estimated_packing).format("DD-MM-YYYY")}</> : <>-</>}</td>
                            <td>{items?.order_cbm ? <>{Number(items?.order_cbm).toFixed(2)}</> : <>-</>}</td>
                            <td>{items?.order_weight ? <>{Number(items?.order_weight)}{" "}{"Kg"}</> : <>-</>}</td>
                            <td>
                                <ul className="p-0">
                                  <li><span>From:</span> {items?.wholesaler_business_profile?.business_address ? <>{items?.wholesaler_business_profile?.business_address}</> : <>-</>}</li>
                                  <li><span>To:</span> {items?.address?.address ? <>{items?.address?.address}</> : <>-</>}</li>
                                </ul>

                            </td>
                          </tr>
                        )

                      })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {console.log("?>>>>>>>>>>>>", totalItems)}
                  {loadsData.length > 0 && totalItems > 10 ?

                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        
      </div>
    </>
  )
}
export default LoadmanagementPending;