import { Spinner } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

export function InlineLoader() {
  return (
    <Spinner style={{ color: "black" }} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export function ThreeDotSpinner(props) {
  return (
    <div className={`loader ${props.className}`}>
      <ThreeDots
        className="loader-dev"
        color="#F5841E"
        height={80}
        width={80}
      />
    </div>
  );
}
