import { Col, Container, Row, OverlayTrigger, Tooltip, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { ThreeDotSpinner } from "../loader";
import { apiBaseUrl,apiUrl } from "../../config/config";
import { Link } from "react-router-dom";
import userImg from "../../assets/images/byDefaultUser.png"
import Pagination from "react-js-pagination";
import { distrixService } from "../../service/api.service";
import swal from "sweetalert";
import moment from "moment";
function OrderList() {

    const [orderLIst, setOrderList] = useState([]);
    const [totalItems, setTotalItems] = useState("");
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(1)
    const [delivery_status, setDelivery_status] = useState("")
    const [getDeliveryStatus, setGetDeliveryStatus] = useState("")
    const [delivery_type, setDelivery_type] = useState("")
    const [search, setSearch] = useState("")
    useEffect(() => {
        getDeliveryStatusList()
    }, [])
    function download_csv(){
        window.open(apiUrl+"/download_csv/?type=order")
    
       }
    async function getOrderlist(search, page, delivery_status, delivery_type) {
        setLoader(true)
        try {
            const params = {
                search: search,
                page: page,
                deliveryStatus: delivery_status,
                deliveryType: delivery_type
            }
            const response = await distrixService.orderListing(params)
            console.log(">>>>>>>>>>>>ordersssslist>>>>........", response)
            if (response?.status == 200) {

                setTotalItems(response?.data?.total_count)
                console.log("orderlist", response?.data?.data)

                setOrderList(response?.data?.data)

                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            if (error?.response?.status == 401) {
                setLoader(false)
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                setLoader(false)
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    (value) => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }

    async function getDeliveryStatusList() {
        setLoader(true)
        try {
            const response = await distrixService.deliveryStatus()
            if (response?.status == 200) {
                console.log("??????status", response?.data)
                setGetDeliveryStatus(response?.data)
                setLoader(false)
                getOrderlist(search, page, delivery_status, delivery_type);

            }
        } catch (error) {
            setLoader(false)
            if (error?.response?.status == 401) {
                setLoader(false)
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                setLoader(false)
                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    (value) => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }

    function handlePageChange(page) {
        console.log("=====page===",page)
        setPage(page);
        getOrderlist(search,page, delivery_status, delivery_type)
    }




    function handleUpdateType(type) {
        setDelivery_type(type)
        setPage(1);
        getOrderlist(search, 1, delivery_status, type)
    }

    function handleUpdateStatus(status) {
        console.log(status)
        setDelivery_status(status)
        setPage(1);
        getOrderlist(search, 1, status, delivery_type)

    }
    function handleUpdateSearch(e) {
        console.log("search", e)
        setSearch(e)
        getOrderlist(e, 1, delivery_status, delivery_type)
    }
    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}

                <Container fluid>
                    <div className="dashboard-area-heading management-heading">

                        <Row className="row mb-3 justify-content-center align-items-center">
                            <Col md={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-end m-0">Orders </h2>
                                    </div>
                                    <div className="heading-top-area-right">
                                        <div className="search-area">
                                            <label>Search Order Number</label>
                                            <form
                                                autoComplete="off"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control inner-input"
                                                    onChange={(e) => handleUpdateSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (!/^\d$/.test(e.key) && e.key !== "Backspace" || (e.key === " " && e.target.value === "")) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"
                                                // onClick={() => user_list(page, search)}
                                                ></i>
                                                <i className="fa fa-close d-none" aria-hidden="true"></i>
                                            </form>
                                        </div>
                                        <div className="user-type-top">
                                            <label>Delivery Type</label>
                                            <div className="arrow-down-area">
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => handleUpdateType(e.target.value)}
                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="1">Distrix Truck</option>
                                                    <option value="2">Wholesaler Own Truck</option>
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Delivery Status</label>
                                            <div className="arrow-down-area">
                                                <select
                                                    className="form-select"
                                                    onChange={(e) => handleUpdateStatus(e.target.value)}
                                                >
                                                    <option value="">Select Status</option>
                                                    {getDeliveryStatus?.length > 0 ? getDeliveryStatus.map((data, index) => {
                                                        if (data.id !== 4) {
                                                            return (
                                                                <option key={index} value={data?.id}>{data?.status}</option>

                                                            )
                                                        }
                                                    })
                                                        : null

                                                    }

                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="export-csv-btn">
                                             <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                                                                      </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Customer Name</th>
                                                <th>Wholesaler</th>
                                                <th>Delivery Type</th>
                                                <th>Order Date</th>
                                                <th>Delivery Status</th>
                                                <th>Commission</th>
                                                <th>Order Price</th>
                                                <th>Payment Status</th>
                                                <th>View Order</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderLIst && orderLIst?.length > 0 ? (
                                                orderLIst.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{data?.quantity[0]?.order.id ? <>{data?.quantity[0]?.order.id}</> : <>-</>}</td>
                                                            <td>{data?.address?.first_name ? <>{data?.address?.first_name + " " + data?.address?.last_name}</> : <>-</>}</td>
                                                            <td className="wholesaler"><Link to={`/userInfo/${data?.wholesaler_business_profile?.user}`}>{data?.wholesaler_business_profile ? <>{data?.wholesaler_business_profile.business_name}</> : <>-</>}</Link></td>
                                                            
                                                            <td>{data.payment_status=="success"?<>{data?.delivery_type == "1" ? <>{"Distrix Truck"}</> : <>{"Wholesaler Own Truck"}</>}</>:"N/A"}</td>
                                                            <td>{data?.created_at ? <>{moment(data?.created_at).format("DD-MM-YYYY")}</> : <>-</>}</td>
                                                            <td>{data?.delivery_status?.status ? <>{data?.delivery_status?.status}</> : <>-</>}</td>
                                                            <td>₱{data?.delivery_commission ? <>{(data?.delivery_commission + data?.order_commission).toFixed(2)}</> : <>-</>}</td>
                                                            <td>₱{data?.order_price ? <>{(data?.order_price + data?.delivery_charge).toFixed(2)}</> : <>-</>} </td>
                                                            <td>{data.payment_status.toUpperCase()}</td>
                                                            <td><Link to={`/OrderDetail/${data?.quantity[0]?.order.id}`}> <i class="fa fa-eye view-eye" aria-hidden="true" ></i></Link></td>
                                                        </tr>
                                                    )
                                                })) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {orderLIst.length > 0 && totalItems > 10 ?

                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={10}
                                            totalItemsCount={totalItems}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default OrderList;