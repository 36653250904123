import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import { useContext } from "react";
import { MyContext } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { distrixService } from "../../service/api.service";
export const Category = () => {
  const navigate = useNavigate()
  const { affiliateRequest, setAffiliateRequest } = useContext(MyContext);
  const [loader, setLoader] = useState(true);
  const [category, setCategory] = useState([]);
  const [category_name, setCategory_name] = useState("");
  const [edit, setEdit] = useState(false);
  const [editCategory_name, setEditCategory_name] = useState("");
  const [editCategory_id, setEditCategory_id] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState()
  const [catErr, setcatErr] = useState("")

  const handleNameChange = (event) => {
    if (edit == true) {
      setEditCategory_name(event.target.value)
    } else {
      setCategory_name(event.target.value);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCategory_name("");
    setEditCategory_name("")
  };


  useEffect(() => {
    categoryDetail(page, search)
  }, [])

  async function categoryDetail(page, search) {
    try {
      setLoader(true)
      const params = {
        page: page,
        search: search
      }
      const response = await distrixService.GetCategoryList(params)
      console.log("category...", response)
      if (response?.status == 200) {
        setCategory(response?.data?.data)
        setLoader(false)
        setTotalCount(response?.data?.total_count)
      }

    } catch (error) {
      console.log("????????", error)
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  async function categoryAdd(data) {
    if (data == "") {
      setcatErr("Please enter category")

    } else {
      setcatErr("")

      try {
        setLoader(true)

        const params = { name: data }
        const response = await distrixService.AddCategory(params)

        if (response?.status == 201) {
          setLoader(false)
          setIsModalOpen(false)
          swal("Success", "Category updated successfully", "success").then((value) => {
            if (value) {
              setCategory_name("")
              setIsModalOpen(false)
              categoryDetail(page, search)
              console.log('OK button clicked after error alert');
            }
          });
        } else if (response?.status == 200) {
          setLoader(false)
          swal("Success", "You have successfully Added Category", "success").then((value) => {
            if (value) {
              setCategory_name("")
              setIsModalOpen(false)
              categoryDetail(page, search)
              console.log('OK button clicked after error alert');
            }
          });
        }
      } catch (error) {
        if (error?.response?.status == 400) {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
            (value) => {
              console.log('OK button clicked after error alert');
            }
          );
        } else if (error?.response?.status == 401) {
          setLoader(false)
          swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        } else {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
            (value) => {
              console.log('OK button clicked after error alert');
            }
          );
        }
      }
    }
  }


  async function categoryEdit(data, id) {
    try {
      setLoader(true)
      const params = { name: data }
      const response = await distrixService.UpdateCategory(params, id)
      if (response?.status == 200) {
        setLoader(false)
        swal("Success", "Category updated successfully", "success").then((value) => {
          if (value) {
            categoryDetail(page, search)
            setIsModalOpen(false)
            setEdit(false)
            console.log('OK button clicked after error alert');
          }
        });
      }
    } catch (error) {
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }


  async function categoryDelete(id) {
    try {
      setLoader(true)
      const response = await distrixService.DeleteCategory(id)
      if (response?.status == 200) {
        setLoader(false)
        swal("Success", "You have successfully Deleted Category", "success").then((value) => {
          if (value) {
            categoryDetail(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });

      } else if (response?.status == 204) {
        setLoader(false)
        swal("Success", "You have successfully Deleted Category", "success").then((value) => {
          if (value) {
            categoryDetail(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });
      }
    } catch (error) {
      if (error?.response?.status == 400) {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );

      } else if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function subCategory(id) {
    navigate(`/subcategory/${id}`);
  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    categoryDetail(pageNumber, search)
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    categoryDetail(1, search)
  }

  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="heading-top-area mb-3">
                <div>
                  <h2 className="main-heading mt-0">Category </h2>
                </div>
                <div className="heading-top-area-right">
                  <div className="search-area me-2">
                    <label>Search</label>
                    <form
                      autoComplete="off"
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        className="form-control inner-input"
                        onChange={(e) => handleUpdateSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                      />
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        onClick={() => {
                          if (search.length > 0) {
                            categoryDetail(page, search)
                          }
                        }
                        }
                      ></i>
                      <i className="fa fa-close d-none" aria-hidden="true"></i>
                    </form>
                  </div>  
                  <div className="add-btn
                  ">
                    <Button onClick={openModal} className="categoryBtn">Add Category</Button>
                  </div>
                  {/* <div className="export-csv-btn">
                    <Button type="button" variant="unset">Export CSV</Button>
                  </div> */}
                </div>

              </div>

              <div className="audit-request-box">
                <>
                  {loader == true ? <ThreeDotSpinner /> : ""}
                  <Row className="pb-2 ">
                    <Col md={12}>
                      <div className="audit-request-box">
                        <Table responsive="sm" className="categoryTable">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Name</th>
                              <th>View Subcategory</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {category && category.length > 0 ? category.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{(page - 1) * 10 + (index + 1)}</td>
                                  <td>{data.name}</td>
                                  <td style={{ cursor: "pointer" }}><i onClick={() => subCategory(data?.id)} class="fa fa-eye view-eye" aria-hidden="true" ></i></td>
                                  <td><Button className="categoryBtn cat_Edit" onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: "Are you sure you want to Update the Category.",
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    }).then((value) => {
                                      console.log("value--->", value)
                                      if (value) {
                                        setIsModalOpen(true);
                                        setEdit(true);
                                        setEditCategory_name(data?.name);
                                        setEditCategory_id(data?.id)

                                      } else {
                                        console.log(
                                          "NO button clicked or popup closed"
                                        );
                                      }
                                    })

                                  }>Edit</Button><Button className="categoryBtn cat_Delete" onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: "Are you sure you want to Delete the Category.",
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    }).then((value) => {
                                      console.log("value--->", value)
                                      if (value) {
                                        categoryDelete(data?.id);

                                      } else {
                                        console.log(
                                          "NO button clicked or popup closed"
                                        );
                                      }
                                    })


                                  }>Delete</Button></td>
                                </tr>
                              )
                            }) : <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg").default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">No data found</h3>
                              </td>
                            </tr>

                            }

                          </tbody>
                        </Table>
                        {category && category.length > 0 && totalCount > 10 ?
                          <Pagination
                            activePage={page}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                            prevPageText={"Prev"}
                            nextPageText={"Next"}
                          />
                          : null
                        }


                      </div>
                    </Col>
                  </Row>
                </>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer position="top-right" autoClose={3000} />
        <Modal show={isModalOpen}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter Category Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              value={edit == false ? category_name : editCategory_name}
              onKeyDown={(e) => {
                if (!e.target.value && e.code === "Space") {
                  e.preventDefault();
                }
              }}
              onChange={handleNameChange}
              className="form-control"
              placeholder="Enter Category"
            />
            <p className="errors">{catErr}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setIsModalOpen(false); setCategory_name(""); setEditCategory_name("") }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => {
              if (edit == false) {
                categoryAdd(category_name)
              } else {
                categoryEdit(editCategory_name, editCategory_id)
              }
            }}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
