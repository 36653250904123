import { Col, Container, Row, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { distrixService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import copy from "copy-to-clipboard";
import swal from "sweetalert";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { apiBaseUrl, length_unit } from "../../config/config";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export function ProductDetails() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const [swiper, setSwiper] = useState()
  const [uploadedimages, setuploadedimages] = useState([]);
  const [previewImage, setpreviewImage] = useState();
  const [productFields, setproductFields] = useState()




  useEffect(() => {
    productDetail()
  }, [])

  async function productDetail() {
    setLoader(true)
    try {
      const response = await distrixService.GetProductDetails(id)
      if (response?.status == 200) {
        console.log(">>>>>>>>>>>>>productdetails", response?.data)
        setLoader(false)
        setData(response?.data)
        setproductFields(response?.data)
        let imgdata = []
        {
          response.data.images.map((img, index) => {

            imgdata.push({ url: apiBaseUrl + img.image })

          })
        }
        console.log(imgdata)
        setuploadedimages(imgdata)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }
  console.log("????????????????????", data)
  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={11}>
                <h2>Product Details</h2>
                <Row className="product-detail">
                  <Col md={12}>
                    {productFields ?
                      <>

                        <Row>
                          <Col md={6} className="mt-5">
                            <div className="product-image-left">
                              {uploadedimages ?
                                <>
                                  <Swiper
                                    slidesPerView={1}
                                    onSwiper={(swiperObj) => {
                                      setSwiper(swiperObj)
                                    }}
                                    modules={[Navigation, Pagination]}
                                    pagination={{ enabled: true }}
                                  >
                                    {
                                      uploadedimages.map((i, tabIndex) => (
                                        <SwiperSlide>
                                          <img src={i.url} />
                                        </SwiperSlide>
                                      ))
                                    }
                                  </Swiper>

                                  <button className="tab-left-arrow-btn" onClick={() => swiper.slidePrev()}><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                                  <button className="tab-right-arrow-btn" onClick={() => swiper.slideNext()}><i class="fa fa-angle-right" aria-hidden="true"></i></button>
                                </>

                                : null}

                            </div>
                          </Col>
                          <Col md={6} className="mt-5 d-flex align-items-center">
                            <div className="product-detail-right w-100">
                              <h6 className="text-light">{productFields?.product?.name}</h6>
                              <h3 className="text-light">{productFields?.product?.category?.name}</h3>
                              <div className="product-availability-order">
                                <p className="text-light">Availability: &nbsp;<span>{productFields?.current_stock} {productFields?.units}</span></p>
                                <p className="text-light">Min Order: &nbsp;<span>{productFields?.min_order} {productFields?.units}</span></p>
                              </div>
                              <h4>₱ {productFields?.price}/<span>{productFields?.units}</span></h4>

                              <p className="text-light">Width: &nbsp;<span>{productFields?.width} {length_unit}</span>Height: &nbsp;<span>{productFields?.height} {length_unit}</span>Length: &nbsp;<span>{productFields?.length} {length_unit}</span></p>

                              <p className="text-light">Weight: &nbsp;<span>{productFields?.weight} {"KG"}</span>Item Per Unit: &nbsp;<span>{productFields?.item_per_unit} </span></p>
                            </div>
                          </Col>

                        </Row>
                        <div className="product-description-area mt-3">
                          <h6>Product Description</h6>
                          <p>{productFields?.description}
                          </p> </div>
                        {productFields?.retail_tip ?
                          <div className="product-description-area">
                            <h6>Retail Tips</h6>
                            <p> {productFields?.retail_tip}</p>
                          </div> : null}
                      </>

                      :
                      <div className="no-dat-found-box">
                        <img
                          src={
                            require("../../assets/images/no-data.svg")
                              .default
                          }
                          alt="da"
                        />
                        <h3 className="text-center text-light">
                          No data found
                        </h3>
                      </div>
                    }
                  </Col>

                </Row>
              </Col>

            </Row>

          </div>
        </Container>
      </div>
    </>
  );
}