import { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { distrixService } from "../../service/api.service";
import swal from "sweetalert";
import { Link, useParams } from "react-router-dom";
import { ThreeDotSpinner } from "../loader";
import { apiBaseUrl, length_unit } from "../../config/config";
import customerImage from '../../assets/images/byDefaultUser.png'
function OrderDetails() {
  const { id } = useParams()
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState()
  const [product, setProduct] = useState([])
  const [customer, setCustomer] = useState("")
  const [sub_total, setSub_total] = useState(0)
  const [wholesaler, setWholesaler] = useState()
  useEffect(() => {
    getOrderDetails()
  }, [])

  useEffect(() => {
    // Calculate the total price whenever the product or data changes
    if (product && product.length > 0 && data?.quantity) {
      let total = 0;

      product.forEach((item, index) => {
        const itemPrice = item?.price ? parseFloat(item.price) : 0;
        const itemQuantity =
          data?.quantity[index]?.quantity ? parseFloat(data.quantity[index].quantity) : 0;
        const price = itemPrice * itemQuantity;

        total += price;
      });

      setSub_total(total); // Update the state with the total price
    } else {
      setSub_total(0); // Reset the total price if there's no product or data
    }
  }, [product, data]);



  async function getOrderDetails() {
    setLoader(true)
    try {
      const response = await distrixService.OrderDetails(id)
      if (response.status == 200) {
        console.log(">>>>>>>orderDetails", response?.data)
        setData(response?.data)
        setProduct(response?.data?.product_sku)
        setCustomer(response?.data?.address)
        setWholesaler(response?.data?.product_sku[0].product.user)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        })
      } else {
        setLoader(false)
        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then((value) => {
          console.log('OK button clicked after error alert');
        })
      }
    }
  }

  console.log("productdetails......", product)
  console.log("customerdetails....", customer)
  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={11}>
                <h2>Order Details</h2>
              </Col>
              <Row className="pb-4">
                <Col md={11}>

                  <div className="user-info-area order-detail-content-area p-0" style={{ display: "block", backgroundColor: "bisque" }}>
                    <>
                      <Row className="row d-flex  mt-4 mb-5">
                        <Col md={8}>
                          
                          <div className="order-detail-left-area location-address-area">
                           <p className="mb-0 mt-3"><img src={require("../../assets/images/location-icon.png")} alt="img" />{data?.wholesaler_business_profile.business_address}</p>
                            <p className="mb-0 mt-2"><img src={require("../../assets/images/location-icon2.png")} alt="img" />{data?.retailer_business_profile.business_address}</p>
                            <h4>Products</h4>
                            <Table>
                              <thead>
                                <th></th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Order Price</th>
                              </thead>
                              <tbody>
                                {product && product.length > 0 ? product.map((item, index) => {
                                  const itemPrice = item?.price ? parseFloat(item.price) : 0;
                                  const itemQuantity = data?.quantity && data?.quantity?.length > 0 ? data.quantity[index]?.quantity ? parseFloat(data.quantity[index].quantity) : 0 : 0;
                                  const price = itemPrice * itemQuantity
                                  return (
                                    <tr key={index}>
                                      <td className="product-image-box">{item?.images ? <img onClick={() => { window.open(apiBaseUrl + item?.images[index].image) }} src={apiBaseUrl + item?.images[index].image} alt="image" className="order-detail-product-image" /> : <>-</>}</td>
                                      <td className="order-product-detail-area">
                                        {item?.product?.name ? <><h6 >{item?.product?.name}</h6><p>Width:<span> {item?.width} {length_unit},</span>  Height:<span> {item?.height} {length_unit},</span> Length:<span> {item?.length} {length_unit}</span> </p></> : <>-</>}</td>
                                      <td className="box-width"> ₱{item?.price ? <>{item?.price}/{item?.units}</> : <>-</>}</td>
                                      <td className="box-width">{itemQuantity ? <>{itemQuantity}</> : <>-</>}</td>
                                      <td className="box-width">₱{item?.price ? <>{price}{ }</> : <>-</>}</td>
                                    </tr>
                                  )
                                })
                                  : null}
                              </tbody>

                            </Table>

                            <Row className="product-bottom">
                              <Col md={10}>
                                <h6>Subtotal:</h6></Col>
                              <Col md={2}>
                                <p>₱{sub_total ? <>{sub_total}</> : 0}</p></Col>
                            </Row>
                            <Row className="product-bottom">
                              <Col md={10}>
                                <h6>Shipping fee:</h6></Col>
                              <Col md={2}><p>₱{data?.delivery_charge ? <>{data?.delivery_charge}</> : 0}</p></Col>
                            </Row>
                            <Row className="product-bottom">
                              <Col md={10}>
                                <h6>Total:</h6></Col>
                              <Col md={2}>
                                <p>₱{(sub_total + data?.delivery_charge).toFixed(2)}</p></Col>
                            </Row>



                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="order-detail-right-area">
                            <div className="retailer-info">
                              <h4>Retailer</h4>
                              <Row className="align-items-center mb-2">
                                <Col md={2} >
                                  <Link to={`/userInfo/${customer?.user?.id}`}>
                                    {customer?.image ? <img className="reatiler-image" src={apiBaseUrl + customer?.image} alt="image" height={50} width={50} /> : <img  className="reatiler-image" src={customerImage} alt="image" height={50} width={50} />}

                                  </Link>
                                </Col>
                                <Col md={10}>
                                  <Link to={`/userInfo/${customer?.user?.id}`} className="detail-user-name">

                                    <p >{customer?.first_name ? <>{customer?.first_name + " " + customer?.last_name}</> : <>-</>}</p>

                                  </Link>
                                </Col>
                              </Row>
                              <div className="order-detail-retailer-info">
                                <h6>Contact info</h6>
                                <span>{customer?.user?.email ? <>{customer?.user?.email}</> : <>-</>}</span>
                              </div>
                              <div className="order-detail-retailer-info">
                                <h6>Phone:</h6>
                              
                              <span> {data?.wholesaler_business_profile.phone?"+"+data?.wholesaler_business_profile.phone:"N/A"}</span>

                              </div>
                              
                              <div className="order-detail-retailer-info">
                                <h6>Delivery Status</h6>
                                <span>{data?.delivery_status?.status}</span>
                              </div>
                              <div className="order-detail-retailer-info">
                                <h6>Phone:</h6>
                              
                    <span> {data?.retailer_business_profile.phone?"+"+data?.retailer_business_profile.phone:"N/A"}</span>

                              </div>
                            </div>

                            <div className="retailer-info mt-3">
                              <h4>Wholesaler</h4>
                              <Row className="align-items-center mb-2">
                                <Col md={2}>
                                 <Link to={`/userInfo/${wholesaler?.id}`}>{wholesaler?.image ? <img  className="reatiler-image"  src={apiBaseUrl + wholesaler?.image} alt="image" height={50} width={50} /> : <img className="reatiler-image" src={customerImage} alt="image" height={50} width={50} />}</Link>
                                </Col>
                                <Col md={10}>
                                  <p className="detail-user-name">{wholesaler?.first_name ? <><Link to={`/userInfo/${wholesaler?.id}`}>{wholesaler?.first_name + " " + wholesaler?.last_name}</Link></> : <>-</>}</p>
                                </Col>
                              </Row>
                              <div className="order-detail-retailer-info">
                                <h6>Email</h6>
                                <span>{wholesaler?.email ? <>{wholesaler?.email}</> : <>-</>}</span>
                             

                              </div>
                              <div className="order-detail-retailer-info">
                                <h6>Phone:</h6>
                              
                    <span> {data?.wholesaler_business_profile.phone?"+"+data?.wholesaler_business_profile.phone:"N/A"}</span>

                              </div>
                            </div>
                          </div>
                        </Col>



                      </Row>

                    </>
                    
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}
export default OrderDetails;