import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { distrixService } from "../../service/api.service";
import {apiUrl} from "../../config/config";
// apiUrl
import moment from "moment";
function Loadmanagement() {
  const { loadStatus } = useParams()
  const [loadsData, setLoadsData] = useState("")
  const [totalItems, setTotalItems] = useState("")
  const [loader, setLoader] = useState(false)
  const [page, setPage] = useState(1)
  const [driverName, setDriverName] = useState("")
  const [truckNumber, setTruckNumber] = useState("")
  const [truckType, setTruckType] = useState("")
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setPage(1)
    getLoadManagementList(1, loadStatus)
  }, [loadStatus])

  async function getLoadManagementList(pageno, types) {
    setLoader(true)
    try {
      const params = {
        page: pageno,
        type: types
      }
      const response = await distrixService.LoadManagementListing(params)
      if (response?.status == 200) {
        setLoader(false)
        setLoadsData(response?.data?.data)
        setTotalItems(response?.data?.total_count)
        console.log("loadslisting....", response)

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        })
      } else {
        swal({ icon: "error", text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then((value) => {
          console.log("ok button clicked after error alert");
        })
      }
    }
  }

  function handlePageChange(pageNumber) {
    console.log("??????", pageNumber)
    setPage(pageNumber)
    getLoadManagementList(pageNumber, loadStatus)
  }

  function truckDetails(name, number, type) {
    console.log(".......<>>>>", name, number, type)
    setDriverName(name)
    setTruckNumber(number)
    setTruckType(type)
    setShow(true)
  }
  function download_csv(){
    window.open(apiUrl+"/download_csv/?type=load&load_type="+loadStatus)
    }
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
        
            <Row className="row justify-content-center align-items-center mb-3">
                                                 <Col md={11}>
                                                        <div className="heading-top-area">
                                                               <div className="d-flex ">
                                                                      <h2 className="align-self-center m-0">  {loadStatus == "upcoming" ? 'Upcoming Loads' : loadStatus == "progress" ? 'In Progress Loads' : loadStatus == "delivered" ? 'Completed Loads' : null}</h2>
                                                               </div>
                                                               <div className="heading-top-area-right">
                                                                      <div className="export-csv-btn">
                                                                             <Button type="button" onClick={download_csv} variant="unset">Export CSV</Button>
                                                                      </div>
                                                               </div>
                                                        </div>
                                                 </Col>
                                          </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Order Id</th>
                        <th>Wholesaler Name</th>
                        <th>Delivery Charge</th>
                        <th>Packed By</th>
                        <th>Total CBM</th>
                        <th>Total Weight</th>
                        <th>Truck Details</th>
                        <th>Load Type</th>
                        <th>Delivery  Type</th>
                        <th>Dispatching</th>
                        <th>Date</th>
                        <th>Time</th>

                      </tr>
                    </thead>
                    <tbody>
                      {loadsData && loadsData.length > 0 ? (loadsData.map((items, index) => {
                        return (
                          <tr key={index}>
                            <td>{(page - 1) * 10 + (index + 1)}</td>
                            <td>{items?.order?.id ? <>{items?.order?.id}</> : <>-</>}</td>
                            <td>{items?.order?.wholesaler_business_profile?.business_name ? <>{items?.order?.wholesaler_business_profile?.business_name}</> 
                            : <>-</>}</td>
                            <td>{items?.load_type == "order" ?
                              <>
                                {items?.order?.delivery_charge ? <>₱ {Number(items?.order?.delivery_charge).toFixed(2)}</> : <>-</>}

                              </>
                              : <>{items?.schedule?.delivery_charge ? <>₱ {Number(items?.schedule?.delivery_charge).toFixed(2)}</> : <>-</>}</>}
                            </td>
                            <td>{items?.order?.estimated_packing ? <>{moment(items?.order?.estimated_packing).format("DD-MM-YYYY")}</> : <>-</>}</td>
                            <td>{items?.order?.order_cbm ? <>{Number(items?.order?.order_cbm).toFixed(2)}</> : <>-</>}</td>
                            <td>{items?.order?.order_weight ? <>{Number(items?.order?.order_weight)}{" "}{"Kg"}</> : <>-</>}</td>
                            <td className="loads-view" onClick={() => truckDetails(items?.truck?.driver?.name, items?.truck?.plate_number, items?.truck?.type?.name)}>{"View"}</td>
                            <td>{items?.load_type == "schedule" ? <>{"Scheduled"}</> : items?.load_type == "order" ? <>{"Distrix Order"}</> : <>-</>}</td>
                            <td>{items?.load_type == "wholesaler" ? <>{"Own Truck"}</> : items?.load_type == "order" ? <>{"Distrix Truck"}</> : <>-</>}</td>
                            <td>
                              {items?.load_type == "order" ?
                                <ul className="p-0">
                                  <li><span>From:</span> {items?.order?.wholesaler_business_profile?.business_address ? <>{items?.order?.wholesaler_business_profile?.business_address}</> : <>-</>}</li>
                                  <li><span>To:</span> {items?.order?.address?.address ? <>{items?.order?.address?.address}</> : <>-</>}</li>
                                </ul>
                                : <ul className="p-0">
                                  <li><span>From:</span> {items?.schedule?.primary_client?.address[0].address ? <>{items?.schedule?.primary_client?.address[0]?.address}</> : <>-</>}</li>
                                  <li><span>To:</span> {items?.schedule?.destination ? <>{items?.schedule?.destination}</> : <>-</>}</li>

                                </ul>
                              }

                            </td>
                            <td>
                              {items?.load_type == "order" ?
                                <>
                                  {items?.order?.estimated_delivery ? <>{moment(items?.order?.estimated_delivery).format("DD-MM-YYYY")}</> : <>-</>}
                                </>
                                : <>{items?.schedule?.pickup_time ? <>{moment(items?.schedule?.pickup_time).format("DD-MM-YYYY")}</> : <>-</>}</>
                              }</td>

                            <td>
                              {items?.load_type == "order" ?
                                <>
                                  {items?.order?.estimated_delivery ? <>{moment(items?.order?.estimated_delivery).format("LT")}</> : <>-</>}
                                </>
                                : <>{items?.schedule?.pickup_time ? <>{moment(items?.schedule?.pickup_time).format("LT")}</> : <>-</>}</>
                              }</td>

                          </tr>
                        )

                      })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* {console.log("?>>>>>>>>>>>>", totalItems)} */}
                  {loadsData.length > 0 && totalItems > 10 ?

                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>


        <Modal show={show} onHide={handleClose} backdrop="static"
          className="truck-detail-box"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Truck Detatil
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              <li><h6>Driver Name</h6>&nbsp;&nbsp;&nbsp;&nbsp;<span>{driverName ? <>{driverName}</>:<>-</>}</span></li>
              <li><h6>Truck Number</h6>&nbsp;&nbsp;&nbsp;&nbsp;<span>{truckNumber ? <>{truckNumber}</>:<>-</>}</span></li>
              <li><h6>Truck Type</h6>&nbsp;&nbsp;&nbsp;&nbsp;<span>{truckType ? <>{truckType}</>:<>-</>}</span></li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
export default Loadmanagement;