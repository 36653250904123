import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import { distrixService } from "../../service/api.service";
import { apiBaseUrl,apiUrl } from "../../config/config";
function ProductList() {
    const [loader, setLoader] = useState(false)
    const [productData, setProductData] = useState([])
    const [totalCount, setTotalCount] = useState()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    useEffect(() => {
        getproduct(page, search)
    }, [])
    function download_csv(){
        window.open(apiUrl+"/download_csv/?type=product")
    
       }
    async function getproduct(page, search) {
        try {
            const params = {
                page: page,
                search: search
            }
            setLoader(true)
            const response = await distrixService.GetProductList(params)
            if (response?.status == 200) {
                console.log("response......getproduct...", response?.data?.data)
                setProductData(response?.data?.data)
                setTotalCount(response?.data?.total_count)
                setLoader(false)
            }
        } catch (error) {
            console.log("errorrrrr...", error)
            if (error?.response?.status == 401) {
                setLoader(false)

                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                setLoader(false)

                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    (value) => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }


    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        setPage(pageNumber);
        getproduct(pageNumber, search)
    }
    function handleUpdateSearch(search) {
        getproduct(1, search)
        setSearch(search);
        setPage(1);

    }
    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}

                <Container fluid>
                    <div className="dashboard-area-heading management-heading">

                        <Row className="row justify-content-center align-items-center">
                            <Col md={11}>
                                <div className="heading-top-area mb-3">
                                    <div className="d-flex">
                                        <h2 className="m-0 align-self-center">Products</h2>
                                    </div>
                                    <div className="heading-top-area-right">
                                        <div className="search-area ">
                                            <label>Search</label>
                                            <form
                                                autoComplete="off"
                                                onSubmit={(event) => {
                                                    event.preventDefault();
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control inner-input"
                                                    onChange={(e) => handleUpdateSearch(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (!e.target.value && e.code === "Space") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"
                                                    onClick={() => {
                                                        if (search.length > 0) {
                                                            getproduct(page, search)
                                                        }
                                                    }
                                                    }
                                                ></i>
                                                <i className="fa fa-close d-none" aria-hidden="true"></i>
                                            </form>
                                        </div>
                                        <div className="export-csv-btn">
                                        <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                                                                      </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="productListTable">
                                        <thead>
                                            <tr>
                                                <th>Sr. No.</th>
                                                <th>Image</th>
                                                <th>Product Name</th>
                                                <th>Category</th>
                                                <th>Subcategory</th>
                                                <th>Current Stock</th>
                                                <th>Ordered</th>
                                                <th>Weight</th>
                                                <th>Price</th>
                                                <th>Net Payout</th>
                                                <th>View Product</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productData && productData?.length > 0 ? (
                                                productData.map((data, index) => {
                                                    { console.log("????>>>>>", data?.images) }
                                                    return (
                                                        <tr key={index}>
                                                            <td>{(page - 1) * 12 + (index + 1)}</td>
                                                            <td>{data?.images ? <img src={apiBaseUrl + data?.images[0]?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <>-</>} </td>
                                                            <td>{data?.product?.name ? <>{data?.product?.name}</> : <>-</>}</td>
                                                            <td>{data?.product?.category?.name ? <>{data?.product?.category?.name}</> : <>-</>}</td>
                                                            <td>{data?.product?.subcategory?.name ? <>{data?.product?.subcategory?.name}</> : <>-</>}</td>
                                                            <td>{data?.current_stock ? <>{data?.current_stock}</> : <>-</>}</td>
                                                            <td>{data?.purchase_count != 0 ? <>{data?.purchase_count}</> : <>0</>}</td>
                                                            <td>{data?.weight ? <>{data?.weight} KG</> : <>-</>}</td>
                                                            <td>₱ {data?.price ? <>{data?.price}</> : <>-</>}</td>
                                                            <td>₱ {data?.net_payout ? <>{data?.net_payout}</> : <>-</>}</td>
                                                            <td><Link to={`/ProductDetails/${data?.id}`}> <i class="fa fa-eye view-eye" aria-hidden="true" ></i></Link></td>
                                                        </tr>
                                                    )
                                                })) : (
                                                <tr className="no-dat-found-box">
                                                    <td colSpan={10}>
                                                        <img
                                                            src={
                                                                require("../../assets/images/no-data.svg")
                                                                    .default
                                                            }
                                                            alt="da"
                                                        />
                                                        <h3 className="text-center text-light">
                                                            No data found
                                                        </h3>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {productData.length > 0 && totalCount > 12 ?

                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={12}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default ProductList;