import { useEffect, useState } from "react";
import { Login } from "./components/login/Login";
import { Home } from "./components/dashboard/Home";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./util/protectedRoute";
import { getItem } from "./util/localStorage";
import { useNavigate } from "react-router-dom";
import { PageNotFound } from "./components/Notfound.js";
import Header from "./common/Header";
import Sidebar from "./common/Sidebar";
import { useLocation } from "react-router";
import Footer from "./common/Footer";
// CSS
import "./App.css";
import "./assets/css/style.css";
import { MyContext } from "./config/config";
import { Category } from "./components/category/Category";
import { SubCategory } from "./components/subcategory/Subcategory";
import { UsersList } from "./components/users/UsersList";
import { UserInfo } from "./components/users/UserProfileDetails";
import ProductList from "./components/products/ProductList";
import { ProductDetails } from "./components/products/ProductDetail";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import OrderList from "./components/order/OrderList";
import OrderDetails from "./components/order/OrderDetails";
import Loadmanagement from "./components/loadsManagement/Loadsmanagement";
import Commission from "./components/commission/CommissionManagement";
import Profile from "./components/profile/AdminProfile";
import { PrimaryClient } from "./components/primaryclient/PrimaryClientManagement";
import LoadmanagementPending from "./components/loadsManagement/PendingLoadsmanagement";
import TransactionList from "./components/transaction/TransactionList";
import UnprocessedTransactionList from "./components/transaction/UnprocessedTransactionList";



function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [affiliateRequest, setAffiliateRequest] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openLoad, setOpenLoad] = useState(false);
  const [editProfile, setEditProfile] = useState(false)
  useEffect(() => {
    if (getItem("safe_tek_admin_token")) {
    } else {
      navigate("/");
    }
  }, []);

  return (
    <MyContext.Provider value={{ affiliateRequest, setAffiliateRequest, filterType, setFilterType, openLoad, setOpenLoad, editProfile, setEditProfile }}>
      <>
        {location.pathname !== "/" ? (
          <>
            <Header />
            <Sidebar />
          </>
        ) : (
          <></>
        )}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/Home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />

          <Route
            path="/Category"
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Subcategory/:id"
            element={
              <ProtectedRoute>
                <SubCategory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/UserList"
            element={
              <ProtectedRoute>
                <UsersList />
              </ProtectedRoute>}
          >
          </Route>
          <Route
            path="/UserInfo/:id"
            element={
              <ProtectedRoute>
                <UserInfo />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/ProductList"
            element={
              <ProtectedRoute>
                <ProductList />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/ProductDetails/:id"
            element={
              <ProtectedRoute>
                <ProductDetails />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/ChangePassword"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/OrderList"
            element={
              <ProtectedRoute>
                <OrderList />
              </ProtectedRoute>
            }
          >
          </Route>
          <Route
            path="/TransactionList"
            element={
              <ProtectedRoute>
                <TransactionList />
              </ProtectedRoute>
            }
          ></Route>
   <Route
            path="/Unprocessed/TransactionList"
            element={
              <ProtectedRoute>
                <UnprocessedTransactionList />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="/OrderDetail/:id"
            element={
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/LoadManagement/:loadStatus"
            element={
              <ProtectedRoute>
                <Loadmanagement />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/LoadManagement/pending"
            element={
              <ProtectedRoute>
                <LoadmanagementPending />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/LoadManagement/"
            element={
              <ProtectedRoute>
                <Loadmanagement />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/Commission"
            element={
              <ProtectedRoute>
                <Commission />
              </ProtectedRoute>
            }
          >

          </Route>
          <Route
            path="/PrimaryClient"
            element={
              <ProtectedRoute>
                <PrimaryClient />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/Admin"
            element={<ProtectedRoute>
              <Profile />
            </ProtectedRoute>}
          >

          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location.pathname !== "/" ? <Footer /> : ""}

      </>
    </MyContext.Provider>
  );
}

export default App;
