import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  Modal,
  Form

} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ThreeDotSpinner } from "../loader";
import { useNavigate, Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import copy from "copy-to-clipboard";
import { distrixService } from "../../service/api.service";
import { apiBaseUrl } from "../../config/config";
import userImg from "../../assets/images/byDefaultUser.png"
import { MyContext,apiUrl } from "../../config/config";
import { useContext } from "react";
import moment from "moment";
import 'bootstrap/dist/css/bootstrap.min.css';
export const UsersList = () => {
  const { filterType, setFilterType } = useContext(MyContext)
  const [userData, setUserData] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)
  const [copyedStripeId, setCopyedStripeId] = useState(false)
  const [type, setType] = useState("")
  const [deactivatedReason, setDeactivatedReason] = useState("")
  const [reasonError, setReasonError] = useState("")
  const [reasonData, setReasonData] = useState("")
  const [userId, setUserId] = useState("")
  const copyToClipboard = (stripeId) => {
    copy(stripeId);
    setCopyedStripeId(true);
    setTimeout(() => {
      setCopyedStripeId(false);
    }, 1000);
  };


  const [show, setShow] = useState(false);

  const handleClose = () => { setShow(false); setReasonError("") };
  const handleShow = () => setShow(true);

  const [viewReason, setViewReason] = useState(false);
  const handelCloseViewReasion = () => setViewReason(false);
  useEffect(() => {
    if (filterType === "retailer") {
      user_list(page, search, "Retailer")
      setType("Retailer")
    } else if (filterType === "wholesaler") {
      user_list(page, search, "Wholesaler")
      setType("Wholesaler")
    } else if (filterType === "trucker") {
      user_list(page, search, "Trucker")
      setType("Trucker")
    } else {
      user_list(page, search, type)
      setType("")
    }
  }, [])
  function download_csv(){
    window.open(apiUrl+"/download_csv/?type=user")

   }
  async function user_list(page, search, type) {
    setLoader(true)
    try {
      const params = {
        page: page,
        search: search,
        type: type
      }
      const response = await distrixService.UserList(params)
      if (response?.status == 200) {
        setUserData(response?.data?.data)
        setTotalItems(response?.data?.totalItems)
        console.log("?????????????list", response)
        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    user_list(pageNumber, search, type)
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    user_list(page, search, type)
  }


  function handleUpdateType(type) {
    setType(type);
    setPage(1);
    user_list(1, search, type)
  }

  function onChangeReason(e) {
    const reason = e.trim()
    setDeactivatedReason(reason)
    console.log("??????????????", reason)

    if (!reason) {
      setReasonError("Please enter the reason")
    } else {
      setReasonError("")
    }
  }

  async function userStatus(id) {
    setLoader(true)
    try {
      const params = show ? { reason: deactivatedReason } : {}

      // const response=await distrixService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await distrixService.ChangeStatus(id, params)
      if (response.status == 200) {
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {
          user_list(page, search, type)
          setShow(false)
          setDeactivatedReason("")
          setReasonError("")
          setUserId("")
        })

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  function disableUser() {
    if (deactivatedReason !== "") {
      console.log("??????????????saveeeebutton click", deactivatedReason)
      userStatus(userId)
      setReasonError("")
      setShow(false)
    } else {
      setReasonError("Please enter the reason")

    }
  }

  function view(data) {
    console.log(">>>>>", data)
    setViewReason(true)
    setReasonData(data)
  }
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Users</h2>
                  </div>
                  <div className="heading-top-area-right">
                    <div className="me-2">
                      <label>Type</label>
                      <div className="arrow-down-area">
                        <select
                          className="form-select"
                          onChange={(e) => handleUpdateType(e.target.value)}
                        >
                          <option value="">Select Type</option>
                          <option value="Wholesaler" selected={filterType === "wholesaler" ? true : false}>Wholesaler</option>
                          <option value="Reseller" selected={filterType === "reseller" ? true : false}>Reseller</option>
                          <option value="Trucker" selected={filterType === "trucker" ? true : false}>Trucker</option>
                        </select>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div className="search-area">
                      <label>Search</label>
                      <form
                        autoComplete="off"
                        onSubmit={(event) => {
                          event.preventDefault();
                        }}
                      >
                        <input
                          type="text"
                          className="form-control inner-input"
                          onChange={(e) => handleUpdateSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (!e.target.value && e.code === "Space") {
                              e.preventDefault();
                            }
                          }}
                        />
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          onClick={() => user_list(page, search, type)}
                        ></i>
                        <i className="fa fa-close d-none" aria-hidden="true"></i>
                      </form>
                    </div>
                    <div className="export-csv-btn">
                    <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="audit-request-box">
                  <Table responsive="sm" className="UserListTable">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Business Name</th>
                        <th>Type</th>
                        <th>Registration Date</th>
                        <th>Deactivate Reason</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData && userData?.length > 0 ? (
                        userData.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * 10 + (index + 1)}</td>
                              <td>{data?.image ? <img src={apiBaseUrl + data?.image} alt="image" height={50} width={50} className="rounded-circle" /> : <img src={userImg} alt="image" height={50} width={50} className="rounded-circle" />} </td>
                              <td>{data?.first_name ? <>{data?.first_name}{" "}{data?.last_name}</> : <>-</>}</td>
                              <td>{data?.email ? <>{data?.email}</> : <>-</>}</td>
                              <td>{data?.business_name ? <>{data?.business_name}</> : <>-</>}</td>
                              <td>{data?.groups[0]?.name ? <>{data?.groups[0]?.name}</> : <>-</>}</td>
                              <td>{data?.date_joined ? <>{moment(data?.date_joined).format("DD-MM-YYYY")}</> : <>-</>}</td>
                              <td style={{ cursor: "pointer" }} onClick={() => view(data?.deactivate_reason)}>
                                {data?.deactivate_reason ?

                                  <span className="loads-view">{"View Reason"}</span>
                                  : <>-</>}


                              </td>
                              <td className="eye-toggle">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: `Are you sure you want to ${data?.is_active ? "Disable" : "Activate"} this Profile?`,
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    })
                                      .then((value) => {
                                        if (value) {
                                          // userStatus(data?.id)
                                          setUserId(data?.id)
                                          if (data?.is_active == true) {
                                            handleShow()
                                          } else {
                                            userStatus(data?.id)
                                          }

                                        } else {
                                          console.log("NO button clicked or popup closed");
                                        }
                                      })

                                  } 
                                  checked={data?.is_active ? true : false}
                                />
                                <Link to={`/userInfo/${data?.id}`}> <i class="fa fa-eye view-eye" aria-hidden="true" ></i> </Link>

                              </td>
                            </tr>
                          )
                        })) : (
                        <tr className="no-dat-found-box">
                          <td colSpan={10}>
                            <img
                              src={
                                require("../../assets/images/no-data.svg")
                                  .default
                              }
                              alt="da"
                            />
                            <h3 className="text-center text-light">
                              No data found
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* {console.log("?>>>>>>>>>>>>", totalItems)} */}
                  {userData.length > 0 && totalItems > 10 ?

                    <Pagination
                      activePage={page}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                      prevPageText={"Prev"}
                      nextPageText={"Next"}
                    />
                    : null
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Deactivated Reasons</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group

                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" onChange={(e) => onChangeReason(e.target.value)} rows={3} placeholder="Enter The Reasons" />
              </Form.Group>
              <span className="text-danger">{reasonError}</span>
            </Form>
          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={disableUser}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>



        <Modal show={viewReason} onHide={handelCloseViewReasion} backdrop="static"

          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Deactivate Reason
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-3">
            <p>
              {reasonData}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handelCloseViewReasion}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};
