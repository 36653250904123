import { Col, Container, Row, OverlayTrigger, Tooltip, Button,Modal,Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { distrixService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import copy from "copy-to-clipboard";
import swal from "sweetalert";
import Swal from "sweetalert2";
import user from "../../assets/images/byDefaultUser.png"
import { apiBaseUrl } from "../../config/config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
export function UserInfo() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [copyedToken, setCopyedToken] = useState(false)
  const [copyedStripeId, setCopyedStripeId] = useState(false)
  const [deactivatedReason, setDeactivatedReason] = useState("")
  const [show, setShow] = useState(false);
  const [viewReason, setViewReason] = useState(false);
  const handelCloseViewReasion = () => setViewReason(false);
  const handleClose = () => { setShow(false); setReasonError("") };
  const [reasonError, setReasonError] = useState("")
  const [reasonData, setReasonData] = useState("")
  const handleShow = () => setShow(true);
  function onChangeReason(e) {
    const reason = e.trim()
    setDeactivatedReason(reason)
    console.log("??????????????", reason)

    if (!reason) {
      setReasonError("Please enter the reason")
    } else {
      setReasonError("")
    }
  }
  function disableUser() {
    if (deactivatedReason !== "") {
      console.log("??????????????saveeeebutton click", deactivatedReason)
      userStatus(id)
      setReasonError("")
      setShow(false)
    } else {
      setReasonError("Please enter the reason")

    }
  }
  const previewId = (img)=>{
    setReasonData(img)
    setViewReason(true)
  }
  const copyToClipboard = (stripeId) => {
    copy(stripeId);
    setCopyedStripeId(true);
    setTimeout(() => {
      setCopyedStripeId(false);
    }, 1000);
  };

  const copyToClipboardToken = (VerificationToken) => {
    copy(VerificationToken);
    setCopyedToken(true);
    setTimeout(() => {
      setCopyedToken(false);
    }, 1000);
  }
  useEffect(() => {
    userDetail()
  }, [])

  async function userDetail() {
    setLoader(true)
    try {
      const response = await distrixService.GetUserProfile(id)
      if (response?.status == 200) {
        console.log(">>>>>>>>>>>>>userInfo", response?.data)
        setLoader(false)
        setData(response?.data)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)

      }
    }
  }

  function backFunctionality() {
    if (localStorage.getItem('tab') == "users") {
      navigate("/UserList")
    } else if (localStorage.getItem('tab') == "primary") {
      navigate("/PrimaryClient")
    }else{
      navigate("/UserList")
    }
  }
  async function userStatus(id) {
    setLoader(true)
    try {
      const params = show ? { reason: deactivatedReason } : {}

      // const response=await distrixService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await distrixService.ChangeStatus(id, params)
      if (response.status == 200) {
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {
          userDetail()
          setShow(false)
          setDeactivatedReason("")
          setReasonError("")
         
        })

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  return (
    <>
      <div className="dashboard-main-area">
        {loader ? <ThreeDotSpinner /> : ""}
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="pb-4 mb-2 justify-content-center">
              <Col md={11}>
                <Row>
                  <Col md={8}>
                    <h2>User Details</h2>
                  </Col>
                  <Col>
                    <Button onClick={backFunctionality} className="userInfo-back">Back</Button>

                  </Col>
                </Row>
              </Col>

              <Row className="pb-4">
                <Col md={9}>

                  <div className="user-info-area" style={{ display: "block", backgroundColor: "bisque" }}>
                  <Row>
                          <Col md={12}>
                          <Form.Check
                          className="profile-top-action-btn"
                                  type="switch"
                                  id="custom-switch"
                                  onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: `Are you sure you want to ${data?.data?.user.is_active ? "Disable" : "Activate"} this Profile?`,
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    })
                                      .then((value) => {
                                        if (value) {
                                          // userStatus(data?.id)
                                          // setUserId(data?.id)
                                          if (data?.data?.user.is_active == true) {
                                            handleShow()
                                          } else {
                                            userStatus(id)
                                          }

                                        } else {
                                          console.log("NO button clicked or popup closed");
                                        }
                                      })

                                  } checked={data?.data?.user.is_active ? true : false}
                                />
                          </Col>

                        </Row>
                    {data && data.data ?

                      <>
                        {console.log("dataresult.....", data)}
                        <Row className="row d-flex align-items-center mt-4 mb-5">
                          <Col className=" d-flex align-items-center">
                            {data?.data?.user?.image ? <img src={apiBaseUrl + data?.data?.user?.image} height={150} width={150} alt="image" className="d-block m-auto rounded-circle" style={{ border: "1px solid #f5841e" }} /> : <img src={user} height={150} alt="image" width={150} className="d-block m-auto rounded-circle" style={{ border: "1px solid #f5841e" }} />}
                          </Col>

                        </Row>
                        
                        <Row className="mb-4">
                          <Col>
                            <p className="user_detailField">First Name:</p>
                            <p className="user_detail">{data?.data?.user?.first_name ? <>{data?.data?.user?.first_name}</> : <>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Last Name:</p>
                            <p className="user_detail">{data?.data?.user?.last_name ? <>{data?.data?.user?.last_name}</> : <>-</>}</p>
                          </Col>
                          <Col>
                            <p className="user_detailField">Email:</p>
                            <p className="user_detail">{data?.data?.user?.email ? <>{data?.data?.user?.email}</> : <>-</>}</p>

                          </Col>
                        </Row>
                        <Row className="mb-4">
                          {(data?.data?.user?.groups[0].name == "Retailer") ?
                            <>
                              <Col>
                                <p className="user_detailField">Business Name:</p>
                                <p className="user_detail">{data?.data?.business_name ? <>{data?.data?.business_name}</> : <>-</>}</p>

                              </Col>
                              <Col>
                                <p className="user_detailField">Business Logo:</p>
                                <p className="user_detail">{data?.data?.business_logo ? <img src={apiBaseUrl + data?.data?.business_logo} alt="image" height={80} width={80} className="rounded" /> : <>-</>}</p>
                              </Col>
                              <Col>
                                <p className="user_detailField">Business Address:</p>
                                <p className="user_detail">{data?.data?.business_address ? <>{data?.data?.business_address}</> : <>-</>}</p>
                              </Col>
                            </>

                            : data?.data?.user?.groups[0].name == "Wholesaler" ?
                              <>
                                <Col>
                                  <p className="user_detailField">Bank Name:</p>
                                  <p className="user_detail">{data?.data?.bank_detail?.bank_name ? <>{data?.data?.bank_detail?.bank_name}</> : <>-</>}</p>
                                </Col>
                                <Col>
                                  <p className="user_detailField">Account Number:</p>
                                  <p className="user_detail">{data?.data?.bank_detail?.last4 ? <>{"......." + data?.data?.bank_detail?.last4}</> : <>-</>}</p>
                                </Col>
                                <Col>
                                  <p className="user_detailField">Contact Number:</p>
                                  <p className="user_detail">{data?.data?.phone ? <>{"+" + data?.data?.phone}</> : <>-</>}</p>
                                </Col>
                              </>
                              :
                              <>
                                <Col>
                                  <p className="user_detailField">Trucking Name:</p>
                                  <p className="user_detail">{data?.data?.business_name ? <>{data?.data?.business_name}</> : <>-</>}</p>
                                </Col>
                                <Col>
                                  <p className="user_detailField">LTFRB Number:</p>
                                  <p className="user_detail">{data?.data?.ltfrb_number !== "undefined" ? <>{data?.data?.ltfrb_number}</> : <>-</>}</p>
                                </Col>

                                <Col>
                                  <p className="user_detailField">Marine Insurance:</p>
                                  <p className="user_detail">{data?.data?.marine_insurance !== "undefined" ? <>{data?.data?.marine_insurance}</> : <>-</>}</p>
                                </Col>
                              </>

                          }


                        </Row>
                        {(data?.data?.user?.groups[0].name == "Wholesaler") || (data?.data?.user?.groups[0].name == "Trucker") ?
                          <Row className="mb-4">
                            {data?.data?.user?.groups[0].name == "Wholesaler" ?
                              <>
                                <Col>
                                  <p className="user_detailField">Business Name:</p>
                                  <p className="user_detail">{data?.data?.business_name ? <>{data?.data?.business_name}</> : <>-</>}</p>

                                </Col>
                                <Col>
                                  <p className="user_detailField">Business Logo:</p>
                                  <p className="user_detail">{data?.data?.business_logo ? <img src={apiBaseUrl + data?.data?.business_logo} alt="image" height={80} width={80} className="rounded" /> : <>-</>}</p>
                                </Col>
                                <Col>
                                  <p className="user_detailField">Business Address:</p>
                                  <p className="user_detail">{data?.data?.business_address ? <>{data?.data?.business_address}</> : <>-</>}</p>
                                </Col>
                                <Col md={12}>
                                  <p className="user_detailField">Registration Date:</p>
                                  <p className="user_detail">{data?.data?.user?.date_joined ? <>{moment(data?.data?.user?.date_joined).format("DD-MM-YYYY")}</> : <>-</>}</p>

                                </Col>
                              </>
                              : data?.data?.user?.groups[0].name == "Trucker" ?
                                <>
                                  <Col>
                                    <p className="user_detailField">Bank Name:</p>
                                    <p className="user_detail">{data?.data?.bank_detail?.bank_name ? <>{data?.data?.bank_detail?.bank_name}</> : <>-</>}</p>
                                  </Col>
                                  <Col>
                                    <p className="user_detailField">Account Number:</p>
                                    <p className="user_detail">{data?.data?.bank_detail?.last4 ? <>{"......." + data?.data?.bank_detail?.last4}</> : <>-</>}</p>
                                  </Col>
                                  <Col>
                                    <p className="user_detailField">DTI/Sec Number:</p>
                                    <p className="user_detail">{data?.data?.dti_sec_number !== "undefined" ? <>{data?.data?.dti_sec_number}</> : <>-</>}</p>
                                  </Col>
                                </>
                                : <></>
                            }


                          </Row >
                          : <></>
                        }

                        {data?.data?.user?.groups[0].name == "Trucker" ?
                          <Row className="mb-4">
                            <Col>
                              <p className="user_detailField">Mayor's Permit Number:</p>
                              <p className="user_detail">{data?.data?.permit_number !== "undefined" ? <>{data?.data?.permit_number}</> : <>-</>}</p>
                            </Col>
                            <Col>
                              <p className="user_detailField">Business Logo:</p>
                              <p className="user_detail">{data?.data?.business_logo ? <img src={apiBaseUrl + data?.data?.business_logo} alt="image" height={80} width={80} className="rounded" /> : <>-</>}</p>
                            </Col>
                            <Col>
                              <p className="user_detailField">Business Address:</p>
                              <p className="user_detail">{data?.data?.business_address ? <>{data?.data?.business_address}</> : <>-</>}</p>
                            </Col>
                          
                          </Row>
                          : <></>
                        }

                        {(data?.data?.user?.groups[0].name == "Trucker") || (data?.data?.user?.groups[0].name == "Retailer") ?
                          <Row className="mb-4">
                            <Col>
                              <p className="user_detailField">Contact Number:</p>
                              <p className="user_detail">{data?.data?.phone ? <>{"+" + data?.data?.phone}</> : <>-</>}</p>
                            </Col>
                            <Col>
                              <p className="user_detailField">Registration Date:</p>
                              <p className="user_detail">{data?.data?.user?.date_joined ? <>{moment(data?.data?.user?.date_joined).format("DD-MM-YYYY")}</> : <>-</>}</p>
                            </Col>
                            {data?.data?.user?.groups[0].name == "Trucker" && apiBaseUrl + data?.data?.govt_id?
                            <Col>
                              <p className="user_detailField">Government Id:</p>
                             < p className="user_detail mb-0"><img src={apiBaseUrl + data?.data?.govt_id} height={30} width={90} alt="image" className="rounded" style={{ border: "1px solid #f5841e" }} /> 
                             
                             </p>
                             <p className="user_detail id-preview-icon">  <i className="fa fa-eye view-eye" aria-hidden="true" onClick={()=>previewId(apiBaseUrl + data?.data?.govt_id)}></i> </p>
                              {/* <p className="user_detail">{data?.data?.govt_id ? <>{data?.data?.govt_id}</> : <>-</>}</p> */}
                            </Col>:null}
                        
                          </Row>
                          : <></>
                        }
                        {data?.data?.user.deactivate_reason?
                      <Row>
                      <Col>
                              <p className="user_detailField">Disable Reason:</p>
                              <p className="user_detail">{data?.data?.user.deactivate_reason ? <>{data?.data?.user.deactivate_reason}</> : <>-</>}</p>
                            </Col>

                      </Row>:null}

                      </>
                      :
                      <div className="no-dat-found-box">
                        <img
                          src={
                            require("../../assets/images/no-data.svg")
                              .default
                          }
                          alt="da"
                        />
                        <h3 className="text-center text-light">
                          No Profile Created Yet
                        </h3>
                      </div>}
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
      <Modal show={viewReason} onHide={handelCloseViewReasion} backdrop="static"

aria-labelledby="contained-modal-title-vcenter"
centered
>
<Modal.Header closeButton>
  <Modal.Title id="contained-modal-title-vcenter">
    Government Id
  </Modal.Title>
</Modal.Header>
<Modal.Body className="pb-3">
  <p>
    <img src={reasonData} />
    
  </p>
</Modal.Body>
<Modal.Footer>
  <Button onClick={handelCloseViewReasion}>Close</Button>
</Modal.Footer>
</Modal>
<Modal show={show} onHide={handleClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Deactivated Reasons</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group

                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Control as="textarea" onChange={(e) => onChangeReason(e.target.value)} rows={3} placeholder="Enter The Reasons" />
              </Form.Group>
              <span className="text-danger">{reasonError}</span>
            </Form>
          </Modal.Body>
          <Modal.Footer className="pt-3">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={disableUser}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
}