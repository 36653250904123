import React, { useEffect } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { distrixService } from "../../service/api.service";
import swal from "sweetalert";
import { Link } from "react-router-dom";
export const PrimaryClient = () => {
       const [clientData, setClientData] = useState([]);
       const [totalItems, setTotalItems] = useState();
       const [loader, setLoader] = useState(false);
       const [page, setPage] = useState(1)
       useEffect(() => {
              getList(page)
       }, [])
       async function getList(page) {
              setLoader(true)
              try {
                     const params = {
                            page: page
                     }
                     const response = await distrixService.getPrimaryList(params)
                     if (response.status == 200) {
                            setLoader(false)
                            console.log("responsePrimaryClientList....", response.data.data)
                            setClientData(response.data.data)
                            setTotalItems(response.data.total_count)
                     }
              } catch (error) {
                     setLoader(false)
                     if (error.response.status == 401) {
                            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                                   localStorage.clear()
                                   window.location.href = "/";
                                   console.log('OK button clicked after error alert');
                            });
                     }
              }
       }

       function handlePageChange(pageNumber) {
              setPage(pageNumber)
              getList(pageNumber)
       }
       return (
              <>
                     <div className="dashboard-main-area">
                            {loader == true ? <ThreeDotSpinner /> : ""}

                            <Container fluid>
                                   <div className="dashboard-area-heading management-heading">
                                    
                                          <Row className="row justify-content-center align-items-center mb-3">
                                                 <Col md={11}>
                                                        <div className="heading-top-area">
                                                               <div className="d-flex ">
                                                                      <h2 className="align-self-center m-0">Primary Clients</h2>
                                                               </div>
                                                               {/* <div className="heading-top-area-right">
                                                                      <div className="export-csv-btn">
                                                                             <Button type="button" variant="unset">Export CSV</Button>
                                                                      </div>
                                                               </div> */}
                                                        </div>
                                                 </Col>
                                          </Row>
                                          <Row className="pb-4 justify-content-center">
                                                 <Col md={11}>
                                                        <div className="audit-request-box">
                                                               <Table responsive="sm" className="UserListTable">
                                                                      <thead>
                                                                             <tr>
                                                                                    <th>Sr. No.</th>
                                                                                    <th>Client Name</th>
                                                                                    <th>Address</th>
                                                                                    <th>Trucker Name</th>
                                                                             </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                             {clientData && clientData?.length > 0 ? (
                                                                                    clientData.map((data, index) => {
                                                                                           return (
                                                                                                  <tr key={index}>
                                                                                                         <td>{(page - 1) * 10 + (index + 1)}</td>
                                                                                                         <td>{data.name ? <>{data.name}</> : <>-</>}</td>
                                                                                                         <td>{data.address.length > 0 ? <>{data.address[0].address}</> : <>-</>}</td>
                                                                                                         <td>{data.user.first_name ? <><Link to={`/userInfo/${data?.user.id}`} className="primaryclient">{data.user.first_name + " " + data.user.last_name}</Link></> : <>-</>}</td>
                                                                                                  </tr>
                                                                                           )
                                                                                    })) : (
                                                                                    <tr className="no-dat-found-box">
                                                                                           <td colSpan={10}>
                                                                                                  <img
                                                                                                         src={
                                                                                                                require("../../assets/images/no-data.svg")
                                                                                                                       .default
                                                                                                         }
                                                                                                         alt="da"
                                                                                                  />
                                                                                                  <h3 className="text-center text-light">
                                                                                                         No data found
                                                                                                  </h3>
                                                                                           </td>
                                                                                    </tr>
                                                                             )}
                                                                      </tbody>
                                                               </Table>
                                                               {clientData.length > 0 && totalItems > 10 ?
                                                                      <Pagination
                                                                             activePage={page}
                                                                             itemsCountPerPage={10}
                                                                             totalItemsCount={totalItems}
                                                                             pageRangeDisplayed={5}
                                                                             onChange={(e) => handlePageChange(e)}
                                                                             prevPageText={"Prev"}
                                                                             nextPageText={"Next"}
                                                                      />
                                                                      : null
                                                               }
                                                        </div>
                                                 </Col>
                                          </Row>
                                   </div>
                            </Container>
                     </div>
              </>
       )
}