import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";

import swal from "sweetalert";
import Swal from "sweetalert2";
import { ThreeDotSpinner } from "../../components/loader";
import { useContext } from "react";
import { MyContext } from "../../config/config";
import { ToastContainer, toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import copy from "copy-to-clipboard";
import { distrixService } from "../../service/api.service";
import { FallingLines } from "react-loader-spinner";
export const SubCategory = () => {
  const { affiliateRequest, setAffiliateRequest } = useContext(MyContext);
  const { id } = useParams()
  console.log("categoryid>>>>>", id)
  const [loader, setLoader] = useState(true);
  const [addsubCategory, setAddsubCategory] = useState("")
  const [subCategory, setSubCategory] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edit, setEdit] = useState(false)
  const [editSub_category, setEditSub_category] = useState("")
  const [subCategoryId, setSubCategoryId] = useState()
  const [categoryId, setCategoryId] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [totalCount, setTotalCount] = useState()
  const [subcatErr, setsubcatErr] = useState("")
  
  const handleNameChange = (event) => {
    if (edit == true) {
      setEditSub_category(event.target.value)
    }
    setAddsubCategory(event.target.value)
  };

  const openModal = () => {
    setIsModalOpen(true);
    setEdit(false)
    setAddsubCategory("")
    setEditSub_category("")
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAddsubCategory("")
  };

  useEffect(() => {
    getAllSubcategory(page, search)
  }, [])

  async function subcategoryAdd() {
    if(addsubCategory==""){
      setsubcatErr("Please enter subcategory")
    }else{
      setsubcatErr("")
    
    setLoader(true)
    try {
      const params = {
        name: addsubCategory,
        category: id
      }
      const response = await distrixService.AddSubcategory(params)
      if (response?.status == 201) {
        if (response?.status == 201) {
          setLoader(false)
          setIsModalOpen(false)
          
          swal("Success", "Subcategory updated successfully", "success").then((value) => {
            if (value) {
              setAddsubCategory("")
              setIsModalOpen(false)
              getAllSubcategory(page, search)
              console.log('OK button clicked after error alert');
            }
          });
        }
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else if (error?.response?.status == 400) {
        setLoader(false)
       
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else {
        setLoader(false)
        
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }
  }


  async function getAllSubcategory(page, search) {
    setLoader(true)
    try {
      const params = {
        page: page,
        search: search
      }
      console.log("???????????????", id)
      const response = await distrixService.GetSubCategoryList(id, params)
      if (response?.status == 200) {
        console.log("getsubcategory/////", response?.data)
        setSubCategory(response?.data?.data)
        setTotalCount(response?.data?.total_count)
        setLoader(false)
      }
    } catch (error) {
      console.log("getsubcategoryerrorrrrr/////", error)
      if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }


  async function editSubcategory(id) {
    setLoader(true)
    try {
      const params = {
        name: editSub_category,
        category: categoryId
      }
      const response = await distrixService.UpdateSubCategory(params, id)
      if (response?.status == 200) {
        setLoader(false)
       
        swal("Success", "You have successfully Added Subcategory", "success").then((value) => {
          if (value) {
            getAllSubcategory(page, search)
            setIsModalOpen(false)
            console.log('OK button clicked after error alert');
          }
        });

      }
    } catch (error) {
      if (error?.response?.status == 400) {
        setLoader(false)
        
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        setLoader(false)
        
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
       
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }


  async function deleteSub_category(id) {
    setLoader(true)
    try {
      const response = await distrixService.DeleteSubCategory(id)
      if (response?.status == 200) {
        setLoader(false)
       
        swal("Success", "You have successfully Deleted Category", "success").then((value) => {
          if (value) {
            getAllSubcategory(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });
      } else if (response?.status == 204) {
        setLoader(false)
        
        swal("Success", "You have successfully Deleted Category", "success").then((value) => {
          if (value) {
            getAllSubcategory(1, search)
            setPage(1)
            console.log('OK button clicked after error alert');
          }
        });
      }
    } catch (error) {
      console.log("?????error", error)
      if (error?.response?.status == 400) {
        setLoader(false)
       
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      } else if (error?.response?.status == 401) {
        setLoader(false)
       
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
          console.log('OK button clicked after error alert');
        });
      } else {
        setLoader(false)
        
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }
  }

  function handlePageChange(pageNumber) {
    console.log("??????page", pageNumber)
    setPage(pageNumber);
    getAllSubcategory(pageNumber, search)
  }

  function handleUpdateSearch(search) {
    setSearch(search);
    setPage(1);
    getAllSubcategory(1, search)
  }

  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <Row className="pb-4 align-items-center">
                <Col md={4}>
                  <h2 className="main-heading">Subcategory</h2>
                </Col>
                <Row className="align-items-end">
                  <Col md={4} className="search-area offset-md-6">
                    <label>Search</label>
                    <form
                      autoComplete="off"
                      onSubmit={(event) => {
                        event.preventDefault();
                      }}
                    >
                      <input
                        type="text"
                        className="form-control inner-input"
                        onKeyDown={(e) => {
                          if (!e.target.value && e.code === "Space") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleUpdateSearch(e.target.value)}
                      />
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        onClick={() => {
                          if (search.length > 0) {
                            getAllSubcategory(page, search)
                          }

                        }
                        }
                      ></i>
                      <i className="fa fa-close d-none" aria-hidden="true"></i>
                    </form>
                  </Col>
                  <Col md={2}>
                    <Button onClick={openModal} className="subcategoryBtn">Add SubCategory</Button>
                  </Col>
                </Row>

              </Row>

              <div className="audit-request-box">
                <>
                  {loader == true ? <ThreeDotSpinner /> : ""}
                  <Row className="pb-2 ">
                    <Col md={12}>
                      <div className="audit-request-box">
                        <Table responsive="sm" className="subcategoryTable">
                          <thead>
                            <tr>
                              <th>Sr. No.</th>
                              <th>Subcategory Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {subCategory && subCategory.length > 0 ? subCategory.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{(page - 1) * 10 + (index + 1)}</td>
                                  <td>{data?.name}</td>
                                  <td><Button className="subcategoryBtn sub_Edit" onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: "Are you sure you want to Update the Subcategory.",
                                      icon: "warning",
                                      buttons: ["NO", "YES"],
                                    }).then((value) => {
                                      console.log("value--->", value)
                                      if (value) {
                                        setIsModalOpen(true);
                                        setEdit(true);
                                        setSubCategoryId(data?.id)
                                        setCategoryId(data?.category)
                                        setEditSub_category(data?.name)

                                      } else {
                                        console.log(
                                          "NO button clicked or popup closed"
                                        );
                                      }
                                    })


                                  }>Edit</Button><Button className="subcategoryBtn sub_Delete" onClick={(e) =>
                                    swal({
                                      title: "Confirmation",
                                      text: "Are you sure you want to Delete the Subcategory.",
                                      icon: "warning",
                                      buttons: ["NO", "YES"], }).then((value) => {
                                      // console.log("value--->", value)
                                      if (value) {
                                        deleteSub_category(data?.id)
                                        } else {
                                        console.log(
                                          "NO button clicked or popup closed"
                                        );
                                      }
                                    })


                                  }>Delete</Button></td>
                                </tr>
                              )
                            }) : <tr className="no-dat-found-box">
                              <td colSpan={10}>
                                <img
                                  src={
                                    require("../../assets/images/no-data.svg").default
                                  }
                                  alt="da"
                                />
                                <h3 className="text-center text-light">No data found</h3>
                              </td>
                            </tr>

                            }

                          </tbody>
                        </Table>
                        {subCategory.length > 0 && totalCount > 10 ?

                          <Pagination
                            activePage={page}
                            itemsCountPerPage={10}
                            totalItemsCount={totalCount}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                            prevPageText={"Prev"}
                            nextPageText={"Next"}
                          />
                          : null
                        }
                      </div>
                    </Col>
                  </Row>
                </>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer position="top-right" autoClose={3000} />
        <Modal show={isModalOpen}
          onHide={closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Enter Subcategory Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              value={edit == false ? addsubCategory : editSub_category}
              onKeyDown={(e) => {
                if (!e.target.value && e.code === "Space") {
                  e.preventDefault();
                }
              }}
              onChange={handleNameChange}
              className="form-control"
              placeholder="Enter Subcategory"
            />
            <p className="errors">{subcatErr}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { setIsModalOpen(false); setAddsubCategory("") }}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => {
              if (edit == true) {
                editSubcategory(subCategoryId)
              } else {
                subcategoryAdd()
              }
            }
            }
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};