
import { Col, Container, Row, OverlayTrigger, Tooltip, Button, Table, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Unauthorized } from "../../config/config";
import { distrixService } from "../../service/api.service";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../loader";
function Commission() {
    const [loader, setLoader] = useState(false)
    const [wholesalersCommission, setWholesalersCommission] = useState('');
    const [truckerCommission, setTruckerCommission]=useState('')
    const [wholesalerError, setWholesalerError] = useState("")
    const [truckerError, setTruckerError]=useState('')

    useEffect(()=>{
        commissionGet()
    },[])

    const handleCommissionChange = (value, type) => {
           const regex = /^(?!100$)(\d{1,2}(\.\d{0,2})?)?$/
        if (regex.test(value)) {
            if (type === "wholesaler") setWholesalersCommission(value)
            if (type === "trucker") setTruckerCommission(value)
        }
    };

   


    async function commissionGet(){
        setLoader(true)
        try{
            const response = await distrixService.getCommission()
            if(response.status == 200){
               setLoader(false)
               console.log("response....getcommission",response.data)
               setTruckerCommission(response.data.trucker)
               setWholesalersCommission(response.data.wholesaler)
            }
        }catch (error){
            setLoader(false)
            if(error?.response?.status == 401){
                Unauthorized()
                
            }
        }
    }
    async function commissionUpdate() {
        setLoader(true)
        try {
            const params={
                wholesaler:wholesalersCommission,
                trucker:truckerCommission
            }
            const response = await distrixService.updateCommission(params)
            if (response?.status == 200) {
                setLoader(false)
                swal("Success", "You have successfully update commission", "success").then((value) => {
                    if (value) {
                        console.log('OK button clicked');
                        commissionGet()
                    }
                });
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false)
                Unauthorized()
            }
           
        }

    }
   

    const submit = async(e) =>{
        e.preventDefault()
        if(wholesalersCommission == ""){
            setWholesalerError("Please enter the commission")
        }
        else if(parseFloat(wholesalersCommission) == 0){
            setWholesalerError("You cannot enter 0")
        }else{
            setWholesalerError("")
        }
         if(truckerCommission == ""){
            setTruckerError("Please enter the commission")
         }
        else if(parseFloat(truckerCommission) == 0){
            setTruckerError("You cannot enter 0")
        }else{
            setTruckerError("")
        }
        if(((wholesalersCommission !== "") && (parseFloat(wholesalersCommission) > 0))  && ((truckerCommission !== "")&& (parseFloat(truckerCommission) > 0)) ){
            console.log("true")
            commissionUpdate()
        }else{
            console.log("false")
        }
    }

    return (
        <>

            <div className="dashboard-main-area">
            {loader == true ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row justify-content-center align-items-center mb-3">
                            <Col md={11}>
                                <Row>
                                    <Col md={4} className="d-flex p-0">
                                        <h2 className="align-self-center m-0">Commission Management</h2>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11} className="commission">
                                <Form className="mt-4 mb-4">
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Enter Wholesalers Commission (In Percentage)</Form.Label>
                                                <Form.Control type="text" className="rounded" placeholder="Enter the commission" value={wholesalersCommission} onChange={(e) => handleCommissionChange(e.target.value, "wholesaler")} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }} />
                                            </Form.Group>
                                            <span className="text-danger">{wholesalerError}</span>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Enter Truckers Commission (In Percentage)</Form.Label>
                                                <Form.Control type="text" className="rounded" placeholder="Enter the commission" value={truckerCommission} onChange={(e) => handleCommissionChange(e.target.value, "trucker")} onKeyDown={(e) => {
                                                    if (e.keyCode === 32) {
                                                        e.preventDefault();
                                                    }
                                                }}/>
                                            </Form.Group>
                                            <span className="text-danger">{truckerError}</span>
                                        </Col>
                                    </Row>

                                    <Button type="submit" className="mt-4 commission-button" onClick={(e) => submit(e)}>
                                        Update
                                    </Button>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
export default Commission;