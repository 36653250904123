import React, { useEffect, useState } from "react";
import {
    Col,
    Container,
    Row, Table, Form, Button
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { distrixService } from "../../service/api.service";
import moment from "moment"
import { ThreeDotSpinner } from "../loader";
import {apiUrl} from "../../config/config";
// apiUrl
function TransactionList() {
    const [loader, setLoader] = useState(false)
    const [transactions, settransactions] = useState([])
    const [totalCount, setTotalCount] = useState()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [start_date, setstart_date] = useState("")
    const [end_date, setend_date] = useState("")
    useEffect(() => {
        gettransaction(page, search)
    }, [])
    const onStartdatechange = (date) => {
        setstart_date(date)
        gettransaction(page, search, date, end_date)
    }
    const onEnddatechange = (date) => {
        setend_date(date)
        gettransaction(page, search, start_date, date)
    }
    async function gettransaction(page, search) {
        try {
            const params = {
                
                page: page,
                search: search
            }
            setLoader(true)
            const response = await distrixService.transaction(params, start_date, end_date)
            if (response?.status == 200) {
                settransactions(response?.data.data)
                setTotalCount(response?.data?.total_count)
                setLoader(false)
            }
        } catch (error) {
            if (error?.response?.status == 401) {
                setLoader(false)
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                    console.log('OK button clicked after error alert');
                });
            } else {
                setLoader(false)

                swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
                    (value) => {
                        console.log('OK button clicked after error alert');
                    }
                );
            }
        }
    }


    function handlePageChange(pageNumber) {
        console.log("??????page", pageNumber)
        setPage(pageNumber);
        gettransaction(pageNumber, search)
    }
   function download_csv(){
    window.open(apiUrl+"/download_csv/?type=transaction&start_date="+start_date+"&end_date="+end_date)
    }

    return (
        <>
            <div className="dashboard-main-area">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">

                        <Row className="row mb-3 justify-content-center align-items-center">
                            <Col md={11}>
                                <div className="heading-top-area">
                                    <div className="d-flex">
                                        <h2 className="align-self-end m-0">Transaction Management </h2>
                                    </div>

                                    <div className="heading-top-area-right">
                                
                                            <div>
                                                <Form.Group
                                                    className=" revenue-summary-right-content"
                                                    controlId="exampleForm.ControlInput1"
                                                >
                                                    <Form.Label>From:</Form.Label>
                                                    <input className="form-control" placeholder="From Date" id="myLocalDate1" type="date" value={start_date} dateFormat="YYYY-MM-DD" onChange={(e) => onStartdatechange(e.target.value)} />
                                                </Form.Group>
                                            </div>
                                            <div>
                                                <Form.Group
                                                    className=" revenue-summary-right-content ms-2"
                                                    controlId="exampleForm.ControlInput2"
                                                >

                                                    <Form.Label className="ms-3">To:</Form.Label>

                                                    <input className="form-control" id="myLocalDate" type="date" max={moment(end_date).format('YYYY-MM-DD ')} dateFormat="YYYY-MM-DD " onChange={(e) => onEnddatechange(e.target.value)} />

                                                </Form.Group>
                                            </div>
                                            {/* <Col md={2}>
                                                <Button type='button' variant='unset' onClick={()=>gettransaction(page)}>Apply</Button>
                                                <Button className="addtruck-btn" style={{color:"#fff"}} variant="unset" type="button"  onClick={()=>get_transactions(page)}>Apply</Button>

                                            </Col> */}

                                     
                                        <div className="export-csv-btn">
                                                         <Button type="button" variant="unset" onClick={download_csv}>Export CSV</Button>
                                                                      </div>
                                        {/* <div className="search-area ">
                                            <label>Search Transaction ID</label>
                                            <form
                                            >
                                                <input
                                                    type="text"
                                                    className="form-control inner-input"

                                                />
                                                <i
                                                    className="fa fa-search"
                                                    aria-hidden="true"

                                                ></i>
                                                <i className="fa fa-close d-none" aria-hidden="true"></i>
                                            </form>
                                        </div> */}
                                        {/* <div className="user-type-top">
                                            <label>User Type</label>
                                            <div className="arrow-down-area">
                                                <select
                                                    className="form-select"

                                                >
                                                    <option value="">Select Type</option>
                                                    <option value="1">Distrix Truck</option>
                                                    <option value="2">Wholesaler Own Truck</option>
                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div> */}
                                        {/* <div >
                                            <label>Status</label>
                                            <div className="arrow-down-area">
                                                <select
                                                    className="form-select"

                                                >
                                                    <option value="">Select Status</option>



                                                </select>
                                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>User Type</th>
                                                <th>Admin Commission</th>
                                                <th>Amount</th>
                                                <th>Transaction Type</th>
                                                <th>Order</th>
                                                <th>Date</th>
                                        
                                                <th>Pay Out Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {transactions && transactions?.length > 0
                                                && transactions.map((data, index) => {
                                                    return (<tr >
                                                        <td>{data.id}</td>
                                                        <td>{data.user.groups[0].name}</td>
                                                        
                                                        <td>{data.admin_commission == 0 ? "-" : data.admin_commission}</td>
                                                        <td>{data.amount}</td>
                                                        <td>{data.transaction_type}</td>
                                                        <td>{data.order.id}</td>
                                                        <td>{moment(data?.created_at).format("DD-MM-YYYY")}</td>
                                                        <td>{data.status}</td>
                                                    
                                                        
                                                    </tr>)
                                                })}
                                        </tbody>
                                    </Table>

                                    {transactions && transactions.length > 0 && totalCount > 12 ?

                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={12}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={(e) => handlePageChange(e)}
                                            prevPageText={"Prev"}
                                            nextPageText={"Next"}
                                        />
                                        : null
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div></>
    );
};
export default TransactionList;