import React, {useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Collapse } from "react-bootstrap";
import { MyContext } from "../config/config";
const Sidebar = ({ isOpen, setIsOpen }) => {
  const {affiliateRequest, setAffiliateRequest, openLoad, setOpenLoad}=useContext(MyContext);
  const location=useLocation()
  console.log(">>>>>>>>>",location.pathname)
   useEffect(()=>{
     if(location.pathname.split("/")[1] == "PrimaryClient"){
      localStorage.setItem('tab', "primary")
     }else if(location.pathname.split("/")[1] == "UserList"){
      localStorage.setItem('tab', "users")
     }else if((location.pathname.split("/")[1] !== "userInfo") && (location.pathname.split("/")[1] !== "PrimaryClient") && (location.pathname.split("/")[1] !== "UserList")){

      localStorage.removeItem('tab');
     }
         if(location.pathname.split("/")[1] == "LoadManagement"){
          setOpenLoad(true)
         }else{
          setOpenLoad(false)

         }
   },[location.pathname])


  return (
    <>
      
      <div className="sidebar-overlay">
        <section className="sidebar">
          <div className="logo">
            <img src={require("../assets/images/logo(1).png")} alt="" />
          </div>
          <div className="">
            <Link to="/Home" className={(location.pathname == "/Home") ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              Dashboard
            </Link>
           
            <Link to="/UserList" onClick={()=>localStorage.setItem('tab', "users")} className={((localStorage.getItem('tab') == "users") && (location.pathname == "/UserList")) || ((localStorage.getItem('tab') == "users") && (location.pathname.split("/")[1] == "userInfo")) || (location.pathname == "/UserList") ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/audit.svg").default}
                alt=""
              />
              Users Management
            </Link>
            <Link to="/PrimaryClient" onClick={()=>localStorage.setItem('tab', "primary")} className={((localStorage.getItem('tab') == "primary") && (location.pathname == "/PrimaryClient")) || ((localStorage.getItem('tab') == "primary") && (location.pathname.split("/")[1] == "userInfo")) || (location.pathname == "/PrimaryClient") ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/primary-client.svg").default}
                alt=""
              />
              Primary Client Management
            </Link>
            <Link to="/Category" className={(location.pathname == "/Category") || (location.pathname.split("/")[1] == "subcategory")  ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard2.svg").default}
                alt=""
              />
              Category Management
            </Link>
            <Link to="/ProductList" className={(location.pathname == "/ProductList") || (location.pathname.split("/")[1] == "ProductDetails")? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard3.svg").default}
                alt=""
              />
              Products Management
            </Link>
            <Link to="/OrderList" className={(location.pathname == "/OrderList") || (location.pathname.split("/")[1] == "OrderDetail") ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/dashboard4.svg").default}
                alt=""
              />
              Orders Management
            </Link>
            <Link to="/TransactionList" className={(location.pathname == "/TransactionList") || (location.pathname.split("/")[1] == "/TransactionList")? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/transaction-management.svg").default}
                alt=""
              />
              Transaction Management
            </Link>
            <Link to="/Unprocessed/TransactionList" className={(location.pathname == "/Unprocessed/TransactionList") || (location.pathname.split("/")[1] == "/Unprocessed")? "sidebar_option active": "sidebar_option"} >
              <img src={require("../assets/images/transaction-management.svg").default} alt=""/>
                Unprocessed Transaction Management
            </Link>
      
            <div className={((location.pathname == "/LoadManagement/upcoming") || (location.pathname == "/LoadManagement/progress") || (location.pathname == "/LoadManagement/delivered") || (location.pathname == "/LoadManagement/pending") ) ? "sidebar_option active": "sidebar_option"}
            onClick={() => setOpenLoad(!openLoad)} aria-controls="example-collapse-text"
            aria-expanded={openLoad}
            >
              <img src={require("../assets/images/loads.svg").default}
                alt=""
               />
               Loads Management
            </div>
            <Collapse in={openLoad}>
               <div className="load-management-menu">
                 <Link to={`/LoadManagement/pending`} className={window.location.pathname == "/LoadManagement/pending" ? "sidebar_option active p-0" : "sidebar_option p-0"}>Pending Load</Link>
                 <Link to={`/LoadManagement/`} className={window.location.pathname == "/LoadManagement/" ? "sidebar_option active p-0" : "sidebar_option p-0"}>All Loads</Link>
                 <Link to={`/LoadManagement/${"upcoming"}`} className={window.location.pathname == "/LoadManagement/upcoming" ? "sidebar_option active p-0" : "sidebar_option p-0"}>Upcoming Load</Link>
                 <Link to={`/LoadManagement/${"progress"}`} className={window.location.pathname == "/LoadManagement/progress" ? "sidebar_option active p-0" : "sidebar_option p-0"}>In-Progress Load</Link>
                 <Link to={`/LoadManagement/${"delivered"}`} className={window.location.pathname == "/LoadManagement/delivered" ? "sidebar_option active p-0" : "sidebar_option p-0"}>Completed Load</Link>

              </div>
            </Collapse>

            <Link to="/Commission" className={(location.pathname == "/Commission") ? "sidebar_option active": "sidebar_option"} >
              <img
                src={require("../assets/images/commission.svg").default}
                alt=""
              />
              Commission Management
            </Link>
          </div>
          
        </section>
      </div>
    </>
  );
};
export default Sidebar;
