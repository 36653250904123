import axios from "axios";
import { getItem } from '../util/localStorage'
import { apiUrl } from "../config/config";


export const distrixService = {
    login,
    Dashboard,
    ChangeStatus,
    AddCategory,
    GetCategoryList,
    UpdateCategory,
    DeleteCategory,
    AddSubcategory,
    GetSubCategoryList,
    UpdateSubCategory,
    DeleteSubCategory,
    UserList,
    GetUserProfile,
    GetProductList,
    GetProductDetails,
    changePassword,
    orderListing,
    deliveryStatus,
    OrderDetails,
    LoadManagementListing,
    LoadManagementPendingList,
    getCommission,
    updateCommission,
    GetProfile,
    ProfileUpdate,
    getPrimaryList,
    transaction,
    re_initiate_payment,
    failed_transaction
}


const headers =() =>
    {
        let token = getItem("safe_tek_admin_token")


        return {headers : { 'Authorization': `Token ${token}` }} 
    };

//    console.log('headers()....', headers());





async function login(data) {
    return await axios.post(`${apiUrl}/login/?type=admin`, data);
}


async function Dashboard(){
    return await axios.get(`${apiUrl}/admin_dashboard`, headers())
}
async function ChangeStatus(id,params){
  return await axios.put(`${apiUrl}/change_user_status/${id}/`, params, headers())
}



async function AddCategory(params){
    return await axios.post(`${apiUrl}/category/`, params, headers())
}

async function GetCategoryList(params){
    return await axios.get(`${apiUrl}/category/?page=${params.page}&search=${params.search}`,headers())
}

async function UpdateCategory(params, id){
    return await axios.put(`${apiUrl}/category/` + id + `/`, params, headers() )
}
async function DeleteCategory(id){
    return await axios.delete(`${apiUrl}/category/` + id, headers())
}






async function AddSubcategory(params){
    return await axios.post(`${apiUrl}/subcategory/`, params, headers())
}

async function GetSubCategoryList(id, params){
    return await axios.get(`${apiUrl}/subcategory/` + id + `/?page=${params.page}&search=${params.search}`, headers())
}

async function UpdateSubCategory(params, id){
    return await axios.put(`${apiUrl}/subcategory/` + id + `/`, params, headers())
}

async function DeleteSubCategory(id){
    return await axios.delete(`${apiUrl}/subcategory/` + id, headers())
}






async function UserList(params){
    return await axios.get(`${apiUrl}/user/?page=${params.page}&search=${params.search}&type=${params.type}`, headers())
}

async function GetUserProfile(id){
    return await axios.get(`${apiUrl}/user_profile/` + id + `/get_user/`, headers())
}






async function GetProductList(params){
    return await axios.get(`${apiUrl}/product/?page=${params.page}&search=${params.search}`, headers())
}

async function GetProductDetails(id){
    return await axios.get(`${apiUrl}/product/`+ id, headers())
}



async function changePassword(data){
    return await axios.put(`${apiUrl}/user/change_password/`,data, headers())
 }



 async function orderListing(params){
    return await axios.get(`${apiUrl}/order/?search=${params.search}&page=${params.page}&delivery_status=${params.deliveryStatus}&delivery_type=${params.deliveryType}`, headers())
 }

 async function deliveryStatus(){
    return await axios.get(`${apiUrl}/delivery_status/`, headers())
 }
 async function OrderDetails(id){
    return await axios.get(`${apiUrl}/order/${id}/`, headers())
 }


 async function LoadManagementListing(params){
    return await axios.get(`${apiUrl}/load/?page=${params.page}&type=${params.type}`,headers())
 }
 async function LoadManagementPendingList(params){
    return await axios.get(`${apiUrl}/order/find_load/?page=${params.page}`,headers())
 }


 async function getCommission(){
    return await axios.get(`${apiUrl}/commission/`,headers())
 }
 async function updateCommission(params){
   return await axios.post(`${apiUrl}/commission/`,params,headers())
 }

 async function GetProfile(id){
    return await axios.get(`${apiUrl}/user/${id}/`,headers())
 }
 async function ProfileUpdate(params, id){
    return await axios.put(`${apiUrl}/user/${id}/`,params, headers())
 }


 async function getPrimaryList(params){
    return await axios.get(`${apiUrl}/primary_clients/?page=${params.page}`,headers())
 }
 async function transaction(params,start_date,end_date){
    return await axios.get(`${apiUrl}/transaction/?page=${params.page}&start_date=`+start_date+'&end_date='+end_date,headers())
 }
 async function failed_transaction(params,type){
    return await axios.get(`${apiUrl}/transaction/failed?page=${params.page}&user_type=`+type,headers())
 }
 async function re_initiate_payment(id){
    return await axios.post(`${apiUrl}/transaction/${id}/reinitiate/`, {}, headers())
  }